.navBar {
    display: table;
    padding:25px 80px;
    vertical-align: middle;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
    line-height: 2em;
    letter-spacing: 0em;
    border: none;
    display: flex;
    width: 100%;
	position:relative;
	z-index:999999;
	
    .logo {
        width: 30%;
        display: table-cell;
        vertical-align: middle;
        border: 0;
        line-height: 1em;
        margin: 0;
        padding: 0;
        
        img {
            max-height: 100px;
            max-width: 400px;
          }
    }
.mobile-sideNav
{
    display:none;
}
    .menu {
        width: 70%;
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        border: 0;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;

        ul {
            display: block;
            padding: 0;
            list-style: none;
            line-height: 1em;
            position: relative;
    
            li {
                display: inline-block;
                text-align: right;
                margin: 0 0 0 30px;
    
                a {
                    color: #7a756f;
					&:hover {
								color:#b1c50a;
							}
                }
				.active-link
				{
					color:#b1c50a;
				}
            }
        }
    }
}

section.portal {
    position:absolute;
	top:0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
	padding-top:152px;
    height: 100%;
    width: 100%;

    > .portal-canvas {
        width: 100%;
        height: 100%;
        position: relative;
        transition: .3s ease all;
        backface-visibility: hidden;

        &.show-nav {
            transform: translateX(80%);
            transform: translateZ(0) translateX(80%);
            transform: translate3d(80%, 0, 0);

            @include tablet() {
                transform: translateX($sidebar-width);
                transform: translateZ(0) translateX($sidebar-width);
                transform: translate3d($sidebar-width, 0, 0);
            }
        }

        > section.main {
            height: 100%;
            transition: padding-left 0.2s ease-in-out;

            @include desktop() {
                // padding-left: $sidebar-width;

                // .overview-open & {
                //     padding-left: $sidebar-width-small;
                // }
            }

            > section.fade {
                height:100%;
                overflow: auto;
            }
        }
    }
}

/*Mobile*/
        @media screen and (min-width: 920px) {
            /*Do not show in mobile or browsers less than 920px*/
            // .menu {
            //     display: none !important;
            // }
          
}
@media screen and (max-width: 920px) {
    .leftMovemain
{ 
    height: 0;
    width:0; 
    padding-top: 0 !important;

    overflow: hidden;
}
.hideSidemenu
{ 
    height: 100%;
    width:100%;
    overflow: hidden;
}
    .menuwindowCLick
    {
        position: fixed;
        top:0;
        width: 100%;
        height: 100%;
        background-color:transparent;
        left: 0;
    }
    .navBar{
        .mobile-sideNav
            {
                position: absolute;;
                top:20px;
                right:20px;
                color:#7a756f;
                font-size: 20px;
                cursor: pointer;
                display: block;
                z-index: 123;
              
            }
        .sideMenuMain
        {
            
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            width: 79%; 
            padding-top:70px;
            background-color: #fff; 
            -webkit-transition: width 0.3s,padding 0.3s, height 0.5s; /* For Safari 3.1 to 6.0 */
            transition: width 0.3s, padding 0.3s, height 0.5s;
            overflow-y: auto;
                ul
                { 
                    font-weight: 500;
                    text-transform: uppercase;
                    font-style: normal;
                    letter-spacing: 0em;
                    text-decoration: none;
                    font-size: 15px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                        li
                        {
                            margin: 0;
                            line-height: 1em;
                            color: #f5f5f5;
                            border-style: solid;
                            border-width: 0 0 1px 0;
                            text-align: center;
                            display: block;
                            position: relative;
                            z-index: 4;
                            a
                            {
                                color: #7a756f;
                                display: block;
                                padding: 20px;
                                font-size: 15px;
                            }
                        }
                }
        }
    }
}
@media only screen and (max-width: 992px)
{
section.portal > .portal-canvas > section.main > section.fade
{
    padding-top: 60px;
}
header.app
{
position:absolute; width:100%; top:0;
}
}